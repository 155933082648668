import React from "react";
// import Homeproductslider from '../components/Homeproductslider';
import "./About.css";
import LightboxGallery from "../components/LightboxGallery";
const About = () => {
  return (
    <>
      <div className="aboutpage page-id-02">
        {/* <div className="aboutrow01">
          <div className="container">
            <div className="container homebtm abtop">
              <h2>What is SpaTX? </h2>
              <h3>Explained in 2 minutes</h3>
              <div className="video-container">
                <iframe
                  src="https://www.youtube.com/embed/XAqGCsirDwU"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="What is SpaTX?"
                />
              </div>
            </div>
            <div className="abtmdes">
                <p>SpaTX is an easy to use solution that helps you make more sales, manage bookings 
seamlessly and make business decisions with real time insights.</p>
            </div>
          </div>
        </div>  */}
        <div className="homerow02 abow02">
        <div className="homerow5">
          <div className="container">
            <div className="twoboxes05">
              <div className="boxlef05">
                <h2>Our Products Highlights</h2>
                <p></p>
              </div>
              <div className="boxrig05"></div>
            </div>
             {/* <div className="sliderrow01">
          <Homeproductslider/>
          </div> */}
<LightboxGallery />
          {/* 
            <div className="abfourboxes01">
              <div className="abfourboxes01box01">
                <img src="/img/products/Settings - Retail1.png" alt="set_up" />
                <h3>SET UP AT EASE</h3>
                <p>
                  5 easy steps to get started, follow the automated flow and
                  enter your valuable data.
                </p>
              </div>
              <div className="abfourboxes01box01">
                <img src="/img/products/Settings - Retail2.png" alt="moment" />
                <h3>IN-MOMENT PULSE CHECK</h3>
                <p>
                  Purposeful Performance Indicators will give you real-time
                  access to the instant data, manage your goals and drive
                  success.
                </p>
              </div>
              <div className="abfourboxes01box01">
                <img src="/img/products/Online Booking3.png" alt="booking" />
                <h3>CONTACTLESS BOOKING & PAYMENT</h3>
                <p>
                  Let your and your customers book and control from anywhere
                  with instant confirmation, focus on the arrival experience
                  journey.
                </p>
              </div>
              <div className="abfourboxes01box01">
                <img src="/img/products/Waitlist.png" alt="" />
                <h3>DYNAMIC WAITLIST</h3>
                <p>
                  Always keep track of your customers, no matter how busy you
                  are. Our smart waitlist ensures you are on top of optimizing
                  every bookings.
                </p>
              </div>
            </div>
            <div className="abfourboxes01 mtop20">
              <div className="abfourboxes01box01">
                <img src="/img/products/Settings - Special Offer.png" alt="assistant" />
                <h3>SPATX ASSISTANT</h3>
                <p>
                  Our SpaTX Assistant centralizes and streamlines your booking
                  process with a sequence of automatic notification.
                </p>
              </div>
              <div className="abfourboxes01box01">
                <img src="/img/products/Settings - Retail3.png" alt="train" />
                <h3>TRAIN AND ONBOARD EFFECTIVELY AND EFFICIENTLY</h3>
                <p>
                  SOPs, Products detail and spa knowledges are all in place,
                  provide the clear descriptions and upselling with systematic
                  flows.
                </p>
              </div>
              <div className="abfourboxes01box01">
                <img src="/img/products/Settings - Staff.png" alt="team" />
                <h3>PREPARE YOUR TEAM FOR SUCCESS</h3>
                <p>
                  Equip your team for up-selling and retailing with systematic
                  flows for the increased and predictable revenue.
                </p>
              </div>
              <div className="abfourboxes01box01">
                <img src="/img/products/Settings - Treatments.png" alt="partnership" />
                <h3>PARTNERSHIP INTEGRATION</h3>
                <p>
                  Open-end possibilities to engage with your specific Hotel
                  operation systems or others operating or marketing tools.
                </p>
              </div>
            </div>  */}
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default About;
