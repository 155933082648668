import React, { useState, useRef } from "react";
import "./LightboxGallery.css";

const LightboxGallery = () => {
  const items = [
    {
      src: "/img/products/Settings - Retail1.png",
      title: "SET UP AT EASE",
      description:
        "5 easy steps to get started, follow the automated flow and enter your valuable data.",
    },
    {
      src: "/img/products/Settings - Retail2.png",
      title: "IN-MOMENT PULSE CHECK",
      description:
        "Purposeful Performance Indicators will give you real-time access to the instant data, manage your goals and drive success.",
    },
    {
      src: "/img/products/Online Booking3.png",
      title: "CONTACTLESS BOOKING & PAYMENT",
      description:
        "Let your and your customers book and control from anywhere with instant confirmation, focus on the arrival experience journey.",
    },
    {
      src: "/img/products/Waitlist.png",
      title: "DYNAMIC WAITLIST",
      description:
        "Always keep track of your customers, no matter how busy you are. Our smart waitlist ensures you are on top of optimizing every booking.",
    },
    {
      src: "/img/products/Settings - Special Offer.png",
      title: "SPATX ASSISTANT",
      description:
        "Our SpaTX Assistant centralizes and streamlines your booking process with a sequence of automatic notifications.",
    },
    {
      src: "/img/products/Settings - Retail3.png",
      title: "TRAIN AND ONBOARD EFFECTIVELY AND EFFICIENTLY",
      description:
        "SOPs, Products detail and spa knowledge are all in place, provide clear descriptions and upselling with systematic flows.",
    },
    {
      src: "/img/products/Settings - Staff.png",
      title: "PREPARE YOUR TEAM FOR SUCCESS",
      description:
        "Equip your team for up-selling and retailing with systematic flows for increased and predictable revenue.",
    },
    {
      src: "/img/products/Settings - Treatments.png",
      title: "PARTNERSHIP INTEGRATION",
      description:
        "Open-end possibilities to engage with your specific Hotel operation systems or other operating or marketing tools.",
    },
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isZoomed, setIsZoomed] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const imageRef = useRef(null);

  const openLightbox = (index) => {
    setCurrentIndex(index);
    setIsOpen(true);
    setIsZoomed(false);
    setPosition({ x: 0, y: 0 }); // Reset position
  };

  const closeLightbox = () => {
    setIsOpen(false);
    setPosition({ x: 0, y: 0 }); // Reset position when closing
  };

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
    setIsZoomed(false);
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length);
    setIsZoomed(false);
  };

  const toggleZoom = () => {
    setIsZoomed(!isZoomed);
    if (!isZoomed) {
      setPosition({ x: 0, y: 0 }); // Reset position on zoom
    }
  };

  const handleMouseDown = (e) => {
    setStartPosition({ x: e.clientX - position.x, y: e.clientY - position.y });
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e) => {
    setPosition({
      x: e.clientX - startPosition.x,
      y: e.clientY - startPosition.y,
    });
  };

  const handleMouseUp = () => {
    window.removeEventListener("mousemove", handleMouseMove);
    window.removeEventListener("mouseup", handleMouseUp);
  };

  return (
    <div>
      <div className="abfourboxes01">
        {items.map((item, index) => (
          <div className="abfourboxes01box01" key={index}>
            <img
              src={item.src}
              alt={item.title}
              onClick={() => openLightbox(index)}
              style={{ cursor: "pointer" }}
            />
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </div>
        ))}
      </div>

      {isOpen && (
        <div className="lightbox-overlay" onClick={closeLightbox}>
          <div className="lightbox-content" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={closeLightbox}>
              &times;
            </span>
            <img
              src={items[currentIndex].src}
              alt={items[currentIndex].title}
              className={isZoomed ? "zoomed" : ""}
              style={{
                transform: isZoomed ? `translate(${position.x}px, ${position.y}px) scale(2)` : 'scale(1)',
                cursor: isZoomed ? 'grabbing' : 'default',
              }}
              ref={imageRef}
              onMouseDown={isZoomed ? handleMouseDown : null}
            />
            <h3>{items[currentIndex].title}</h3>
            <p>{items[currentIndex].description}</p>

            <button className="nav-button zoom" onClick={toggleZoom} style={{ position: 'absolute', top: '20px', left: '20px' }}>
              {isZoomed ? <i className="fa fa-search-minus" /> : <i className="fa fa-search-plus" />} 
            </button>

            <div className="lightbox-navigation">
              <button className="nav-button prev" onClick={prevImage}>
                <i className="fa fa-chevron-left"></i>
              </button>
              <button className="nav-button next" onClick={nextImage}>
                <i className="fa fa-chevron-right"></i>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LightboxGallery;
