import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import "./Footer.css";
const Footer = () => {
    const [isVisible, setIsVisible] = useState(false);
    const currentYear = new Date().getFullYear();
  
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
  
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };
  
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);
  return (
    <>
      <div className="footer footerct">
        <div className="foocol">
        <div className="container">
          <div className="row"></div>
          <Link to="/"><figure>
            <img src="/img/ftr_logo.png" alt="Footer Logo" />
          </figure></Link>
          <ul className="ftr-list">
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <a href="#pricing">Pricing</a>
            </li>
            <li>
              <Link to="/request-a-demo">Contact Us</Link>
            </li>
          </ul>
         {/*  <ul className="social-icons">
            <li>
                <a href="https://www.facebook.com" className="social-icon-footer" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                    <i className="fa fa-facebook-f"></i>
                </a>
            </li>
            <li>
                <a href="https://www.twitter.com" className="social-icon-footer" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
                    <i className="fa fa-twitter"></i>
                </a>
            </li>
        </ul> */}
        <p>© {currentYear} All Rights Reserved.</p>
        </div>
      </div>
            {/* Scroll to Top Button */}
            {isVisible && (
          <button className="scroll-to-top" onClick={scrollToTop}>
           <i class="fa fa-long-arrow-up"></i>
          </button>
        )}
      </div>
    </>
  );
};

export default Footer;
